<template>
    <div>
      <h2>添加单词</h2>
      <form @submit.prevent="submitWord">
        <input
          v-model="newWord.english"
          type="text"
          placeholder="输入英文单词"
          required
        />
        <input
          v-model="newWord.chinese"
          type="text"
          placeholder="输入中文释义"
          required
        />
        <button type="submit">添加</button>
      </form>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        newWord: {
          english: '',
          chinese: '',
        },
      };
    },
    methods: {
      submitWord() {
        this.$emit('add-word', this.newWord); // 发送到父组件
        this.newWord.english = '';
        this.newWord.chinese = '';
      },
    },
  };
  </script>
  