<template>
    <li>
      <p>
        {{ word.english }}
        <button @click="$emit('toggle', word._id)">
          {{ word.showChinese ? '隐藏释义' : '显示释义' }}
        </button>
        <button @click="$emit('delete', word._id)">删除</button>
      </p>
      <p v-if="word.showChinese">{{ word.chinese }}</p>
    </li>
  </template>
  
  <script>
  export default {
    props: {
      word: {
        type: Object,
        required: true,
      },
    },
  };
  </script>
  