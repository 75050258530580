<template>
  <div id="app">
    <h1>欢迎来到背单词应用</h1>
    <div class="container">
      <AddWord @add-word="addWord" />
      <WordList :words="words" @toggle="toggleChinese" @delete="deleteWord" />
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import AddWord from './components/AddWord.vue';
import WordList from './components/WordList.vue';

export default {
  components: { AddWord, WordList },
  data() {
    return {
      words: [], // 单词列表
    };
  },
  methods: {
    async fetchWords() {
      try {
        const response = await axios.get('https://word-app-backend-c8fu.onrender.com/api/words');
        this.words = response.data;
      } catch (error) {
        console.error('Error fetching words:', error);
      }
    },
    async addWord(newWord) {
      try {
        await axios.post('https://word-app-backend-c8fu.onrender.com/api/words', newWord);
        await this.fetchWords(); // 添加成功后刷新列表
      } catch (error) {
        console.error('Error adding word:', error);
      }
    },
    async toggleChinese(id) {
      const word = this.words.find((w) => w._id === id); // 确保找到具体单词
      if (word) {
        word.showChinese = !word.showChinese; // 切换状态
        this.words = [...this.words]; // 触发响应式更新
      }
    },
    async deleteWord(id) {
      try {
        await axios.delete(`https://word-app-backend-c8fu.onrender.com/api/words/${id}`);
        await this.fetchWords(); // 删除成功后刷新列表
      } catch (error) {
        console.error('Error deleting word:', error);
      }
    },
  },
  created() {
    this.fetchWords(); // 初始化时获取单词列表
  },
};
</script>

<style scoped>
#app {
  font-family: 'Arial', sans-serif;
  text-align: center;
  color: #333;
  background: linear-gradient(135deg, #f5f7fa, #c3cfe2);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
}

h1 {
  color: #42b983;
  font-size: 36px;
  margin-bottom: 20px;
  font-weight: bold;
}

.container {
  background: #fff;
  border-radius: 8px;
  padding: 20px;
  width: 100%;
  max-width: 600px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

button {
  background-color: #42b983;
  color: white;
  border: none;
  padding: 8px 16px;
  margin: 5px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

button:hover {
  background-color: #367f5b;
}

input[type="text"], input[type="submit"] {
  border: 1px solid #ddd;
  padding: 10px;
  border-radius: 4px;
  width: 100%;
  margin-bottom: 10px;
  box-sizing: border-box;
}

input[type="text"]:focus, input[type="submit"]:focus {
  outline: none;
  border-color: #42b983;
}

ul {
  list-style-type: none; /* 去除圆点 */
  padding: 0;
}

li {
  background-color: #f9f9f9;
  padding: 12px 20px;
  margin: 10px 0;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
}

li button {
  margin-left: 10px;
  background-color: #e74c3c;
}

li button:hover {
  background-color: #c0392b;
}

.word {
  font-size: 18px;
  font-weight: 600;
  color: #333;
}

.chinese {
  font-size: 16px;
  color: #888;
  margin-left: 10px;
}

p {
  margin: 0;
  padding: 0;
}

p span {
  font-weight: bold;
}
</style>
