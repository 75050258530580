<template>
    <div>
      <h2>单词列表</h2>
      <ul>
        <WordItem
          v-for="word in words"
          :key="word._id"
          :word="word"
          @toggle="toggleChinese(word._id)"
          @delete="deleteWord(word._id)"
        />
      </ul>
    </div>
  </template>
  
  <script>
  import WordItem from './WordItem.vue';
  
  export default {
    components: { WordItem },
    props: {
      words: {
        type: Array,
        required: true,
      },
    },
    methods: {
      toggleChinese(id) {
        this.$emit('toggle', id); // 向父组件发送事件
      },
      deleteWord(id) {
        this.$emit('delete', id); // 向父组件发送事件
      },
    },
  };
  </script>
  